exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "html,\nbody,\n.index_app_3Qs6X {\n  /* probably unecessary, transitional until layout is refactored */\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  /* Setting min height/width makes the UI scroll below those sizes */\n  min-width: -moz-fit-content;\n  min-width: fit-content;\n  /* min-height: 640px; */\n  /* Min height to fit sprite/backdrop button */\n}\n\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n\n* {\n  box-sizing: border-box;\n}\n\n/*滚动条样式*/\n\n::-webkit-scrollbar {\n  width: 6px;\n  background-color: none;\n}\n\n::-webkit-scrollbar-track {\n  background-color: none;\n}\n\n::-webkit-scrollbar-thumb {\n  background: rgba(188, 192, 201, 1);\n  opacity: 0.5;\n  border-radius: 10px;\n}\n\n::-webkit-scrollbar-button:start {\n  background-size: 12px 12px;\n}\n\n::-webkit-scrollbar-button:end {\n  background-size: 12px 12px;\n}", ""]);

// exports
exports.locals = {
	"app": "index_app_3Qs6X"
};